import React, { useState, useEffect } from 'react';
import Player from './components/Player';
import LoginForm from './components/LoginForm';
import './App.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);

    // Helper to read the "sessionId" cookie
    const getCookie = (name) => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? match[2] : null;
    };

    useEffect(() => {
        const checkOrCreateSession = async () => {
            try {
                let sessionId = localStorage.getItem('sessionId'); // Try reading from localStorage
                if (!sessionId) {
                    console.log('No session cookie found. Creating session...');
                    // Call your usermanagement route via Kong
                    const createResponse = await fetch('/api_usermanagement/sessions/create-anonymous-session', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            session_id: 'anonTest',
                            data: { ip: '127.0.0.1', preferences: {} }
                        })
                    });
                    // Capture the session ID from the header
                    sessionId = createResponse.headers.get('x-session-token');
                    console.log('Session ID from header:', sessionId);
                    if (sessionId) {
                        localStorage.setItem('sessionId', sessionId);
                    } else {
                        console.error('No session ID returned from API!');
                        setLoading(false);
                        return;
                    }

                    const createData = await createResponse.json();
                    console.log('Create session response:', createData);
                    console.log([...createResponse.headers.entries()]);

                    // Let the browser set the cookie from the response headers
                    // Now re-check for the sessionId
                    sessionId = getCookie('sessionId');
                    if (!sessionId) {
                        console.error('Session cookie not set after creation!');
                        setLoading(false);
                        return;
                    }
                }

                // Now check the session
                const checkResponse = await fetch(`/api_usermanagement/sessions/check-session/${sessionId}`);
                const data = await checkResponse.json();
                console.log('Check session response:', data);

                if (data.valid) {
                    setSession(data.session);
                    // If your session includes something that indicates “logged in,” set isLoggedIn accordingly
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking/creating session:', error);
            } finally {
                setLoading(false);
            }
        };

        checkOrCreateSession();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="app-container">
            {isLoggedIn ? (
                <>
                    <button onClick={() => setIsLoggedIn(false)}>Logout</button>
                    <Player session={session} />
                </>
            ) : (
                <>
                    <LoginForm onLogin={() => setIsLoggedIn(true)} />
                    <Player session={session} />
                </>
            )}
        </div>
    );
}

export default App;
